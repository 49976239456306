#q-verification {
    text-align: center;
    width: 90%;
    margin: 0 auto;

}


#q-verification .hr-text:before {
    content: '';

    background: linear-gradient(to right, transparent, black, transparent);
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
}

#q-verification .hr-text:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    color: black;

    padding: 0 .5em;
    line-height: 1.5em;

    color: black;
    background-color: white;
}

#q-verification .btn-check:checked+.btn{
    background-color: #39a6de;
    border-color: #39a6de;
}


@media only screen and (max-width: 640px) {

    #q-verification .row{
        display: initial;
    }
}