#step-six-table {
    width: 40%;
    margin: 0 auto;
    text-align: center;
}

#step-six-table p {
    margin-bottom: 0px;
}

#table-amortization {
    width: 90%;
    margin: 0 auto;
}

#table-amortization td {
    padding: 5px;
    vertical-align: top;
}

#step-six-table td {
    padding: 10px;
    vertical-align: top;
}

.bg-custom {
    background-color: white;
    color: gray;
    border-color: gray;
    border: solid 1px;
    margin-right: 3px;
}

.invitation-icon {
    width: 20px;
    padding-bottom: 5px;
    margin-right: 10px;
}

.modal-plan {

    max-width: 100% !important;
}

.my-modal {
    height: 100px;
    overflow-y: scroll;
}

#table-amortization th {
    padding: 5px !important;
}

.td-input-invi {
    width: 50%;
}

@media only screen and (max-width: 700px) {
    .td-input-invi {
        width: 100%;
    }

    #step-six-table {
        width: 100%;
    }

    #step-six-table td {
        display: block;
    }

    #table-amortization {
        width: 100%;
    }

    #table-amortization td,
    #table-amortization th {
        border-bottom: 1px solid #ddd;
        display: block;
        font-size: .8em;
        text-align: right;

        padding: 5px !important;


    }



    #table-amortization tr {
        border-bottom: 3px solid #ddd;
        display: block;
        margin-bottom: .625em;
    }

    #table-amortization td::before {
        /*
        * aria-label has no advantage, it won't be read inside a table
        content: attr(aria-label);
        */
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-transform: uppercase;
    }

    #table-amortization td:last-child {
        border-bottom: 0;
    }

    #table-amortization thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    .modal-payday-content{
        width: 100% !important;
        margin: 0 auto !important;
    }

    .modal-payday{
        width: 95% !important;
        max-width: 95% !important;
    }
}


.modal-payday{
    width: 50%;
    max-width: 50%;
}

.modal-payday-content{
    width: 50%;
    margin: 0 auto;
}