#step-three-table {
    width: 30%;
    margin: 0 auto;
    text-align: center;
}


#step-three-table .form-control {
    display: initial;
}

#step-three-table .input-otp {
    width: 80%;
    text-align: center;
    font-weight: bold;
}


@media only screen and (max-width: 820px) {

    #step-three-table {
        width: 90%;
        margin: 0 auto;
    }
    #step-three-table .input-otp {
        width: 90%;
        text-align: center;
    }
}

#step-three-table input::-webkit-outer-spin-button,
#step-three-table input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

#step-three-table input[type=number] {
    -moz-appearance: textfield;
}