#step-two-table {
    width: 50%;
    margin: 0 auto;
}


.intl-tel-input,
input[type=tel] {
    width: 100%;
    height: 58px;
}

.btn-mp-back {
    background-color: #989AA5;
    border-color: #989AA5;
    color: white;
}

.btn-mp-back:hover {
    background-color: #888888;
    color: white;
}


.modal-aut {

    max-width: 80% !important;
}



#step-two-table td {
    padding: 10px;
}

.hr-text {
    line-height: 1em;
    position: relative;
    outline: 0;
    border: 0;
    color: black;
    text-align: center;
    height: 1.5em;
    opacity: 1;


}

.hr-text:before {
    content: '';

    background: linear-gradient(to right, transparent, gray, transparent);
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
}

.hr-text:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    color: black;

    padding: 0 .5em;
    line-height: 1.5em;

    color: gray;
    background-color: white;
}


#divGeneros .col {
    margin-bottom: 10px;
}

#step-two-table .form-check-label{
    margin-top: 5px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@media only screen and (max-width: 700px) {

    #step-two-table {
        width: 100%;
        margin: 0 auto;
    }

    #step-two-table td {
        display: block;

    }

    #step-two-table .form-check-label{
        margin-top: 0px;
    }

    .modal-aut {

        max-width: 100% !important;
    }
   
}


.iti-mobile .intl-tel-input.iti-container {
    left: 0px !important;
    height: 100% !important;
}