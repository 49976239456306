* {
  margin: 0;
  padding: 0;
}


.logo-container {
  text-align: center;
}

.rbt-aux {
  position: absolute;
  top: 50%;
  right: 1%;
  transform: translateY(-50%);
}

.rbt-aux span {
  display: none;
}



.form-floating {
  width: 100%;
}

.form-control {
  border-width: 2px;
}

.my-loader {
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  background-color: rgba(255, 255, 255, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
}


.my-loader-container {
  position: relative;
  min-width: 320px;
  min-height: 200px;
  text-align: center;
  padding: 1rem;
}



.cookie-consent-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 2147483645;
  box-sizing: border-box;
  width: 100%;

  background-color: #F1F6F4;
}



.cookie-consent-banner__inner {
  max-width: 960px;
  margin: 0 auto;
  padding: 32px 0;
}

.cookie-consent-banner__copy {
  margin-bottom: 16px;
}

.cookie-consent-banner__actions {}

.cookie-consent-banner__header {
  margin-bottom: 8px;

  font-family: "CeraPRO-Bold", sans-serif, arial;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

.cookie-consent-banner__description {
  font-family: "CeraPRO-Regular", sans-serif, arial;
  font-weight: normal;
  color: #838F93;
  font-size: 16px;
  line-height: 24px;
}

.cookie-consent-banner__cta {
  box-sizing: border-box;
  display: inline-block;
  min-width: 164px;
  padding: 11px 13px;

  border-radius: 2px;

  background-color: #00A9EB;

  color: #FFF;
  text-decoration: none;
  text-align: center;
  font-family: "CeraPRO-Regular", sans-serif, arial;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
}

.cookie-consent-banner__cta--secondary {
  padding: 9px 13px;

  border: 2px solid #3A4649;

  background-color: transparent;

  color: #00A9EB;
}

.cookie-consent-banner__cta:hover {
  background-color: #00A9EB;
  color: white;
  background-color: #00a0df;
}

.cookie-consent-banner__cta--secondary:hover {
  border-color: #838F93;

  background-color: transparent;

  color: #00A9EB;
}

