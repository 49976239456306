#step-five-table {
    width: 50%;
    margin: 0 auto;
}


#step-five-table td {
    padding: 10px;
    min-width: 50%;
    width: 50%;
    max-width: 50%;
}

@media only screen and (max-width: 700px) {

    #step-five-table {
        width: 100%;
        margin: 0 auto;
    }
    #step-five-table td {
        display: block;
        min-width: 100%;
        width: 100%;
        max-width: 100%;
    }
}