#manual-validation-table {
    width: 80%;
    margin: 0 auto;
    text-align: center;
}


#manual-validation-table td {
    padding-bottom: 30px;
}

.divPicture {
    background-color: #efefef;
    border-radius: 15px;
    width: 30%;
    margin: 0px auto;
    border: 1px black;
    border-style: solid;
    padding: 10px;
    cursor: pointer;
}

.manual-validation-container {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.manual-validation-cam {
    text-align: center;
    height: 100vh;
}

.manual-validation-pictures {
    width: 50%;
    display: grid;
}


.imgPicture {
    width: 100px;
    
    border-radius: 5px;
}

.tempImgPicture {
    width: 300px;
    max-height: 300px;
    border-radius: 5px;
}

.manual-validation-icon {
    color: black;
    font-size: 40px;
    cursor: pointer;
    margin-left: 10px;
    vertical-align: middle;
}

@media only screen and (max-width: 850px) {

    .manual-validation-cam {
        width: 100%;
    }

    .manual-validation-pictures {
        width: 100%;

    }

    .divPicture {

        width: 85%;

    }
}