.divBox {
    background-color: #efefef;
    border-radius: 15px;
    margin: 0px auto;
    border: 1px black;
    border-style: solid;
    padding: 10px;
    cursor: pointer;
    text-align: center;
    width: 50%;
}



#dc-table {
    width: 60%;
    margin: 0 auto;
}

#dc-table td {
    padding: 10px;
}

@media only screen and (max-width: 1100px) {

    #dc-table {
        width: 100%;
        margin: 0 auto;
    }

    .divBox {
        width: 90%;

    }

}