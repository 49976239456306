#table-survey {
    width: 60%;
    margin: 0 auto;
    text-align: center;
    color: black;
}


#survey-div .btn-outline-primary {
    background-color:white;
    border-color: #00A9EB;
    color: #00A9EB;
}

#survey-div .btn-check:checked+.btn {
    background-color:#00A9EB;
    border-color: #00A9EB;
    color: white;
}

#table-survey td {
    padding: 10px;
}

.radio-mp {
    width: 30px;
    height: 30px;
}





@media only screen and (max-width: 700px) {

    #table-survey {
        width: 100%;
        margin: 0 auto;
    }

    #table-surveytd {
        display: block;

    }
}