@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;800&display=swap');

.containerPrincipalHome {
    color: black;
    background-color: #00A9EB;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
    font-size: 50px;
    align-items: center;
    overflow: hidden;
}

.containerImagenHome {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.imagenMeddiPayHome {
    max-width: 100%;
    height: auto;
}

.containerOtrosHome{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.buttonSolicitarCreditoHome {
    width: 50vh;
    padding: 5px;
    border: none;
    border-radius: 8px 8px 8px 8px;
    cursor: pointer;
    outline: none;
    background: #79D853;
    color: white;
    margin: 30px 50px 10px 50px;
    font-size: x-large;
    font-weight: 500;
}

.separadorHome {
    display: flex;
    width: 56vh;
    font-size: small;
}

.containerComodinHome {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100vh;
    border-bottom: 1px solid black;
    margin-bottom: 5px;
}

.iniciarSesionHome {
    color: white;
    margin: 10px 50px 10px 50px;
    font-size: medium;
}

.olvidoRefHome {
    color: black;
    margin: 10px 50px 10px 50px;
    font-size: medium;
}

@media (max-width: 600px) {
    .imagenMeddiPayHome {
        max-width: 80%;
        height: auto;
    }
 
    .containerPrincipalHome {
        font-size: 30px;
    }
    .buttonSolicitarCreditoHome {
        margin: 30px 50px 10px 50px;
        font-size:medium;
    }
       
}