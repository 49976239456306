:root {
    --nextui-box-shadow-medium: 0px 0px 15px 0px rgba(0, 0, 0, .03), 0px 2px 30px 0px rgba(0, 0, 0, .08), 0px 0px 1px 0px rgba(0, 0, 0, .3);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: var(--nextui-box-shadow-medium);
}


#dashboard-table {
    width: 80%;
    margin: 0 auto;
    text-align: center;

}

.dashboard-accordion {
    width: 40%;
    margin: 0 auto;
}

.accordion-button:not(.collapsed) {
    color: black !important;
    background-color: white !important;

}

.bage-primary-custom {
    background-color: #39a6de !important;
}

.bage-green-custom {
    background-color: #34e30c !important;
}

.bage-gray-custom {
    background-color: gray !important;
}

.accordion {

    --bs-accordion-btn-focus-box-shadow: ;

}

.accordion-button,
.accordion-button:focus {
    box-shadow: inset 0px -1px #dee2e6;
    font-weight: bold;
}

.card {
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.titulos-mis-creditos {
    font-size: 15px;
    font-weight: bold;
    color: gray;
}

.textos-mis-creditos {
    font-size: 17px;
    font-weight: bold;

}

#dashboard-table td,
#dashboard-table th {

    width: 33%;
}

.btn-dashboard {
    width: 30%;

}

#btn-update-profile {
    background-color: #699bff;
    border-color: #699bff;
    color: white;
}

#btn-update-profile:hover {
    background-color: #417efa;
    color: white;
}

#btn-credits {
    background-color: #fbb042;
    border-color: #fbb042;
    color: white;
}

#btn-credits:hover {
    background-color: #f79707;
    color: white;
}

#btn-allys {
    background-color: #34e30c;
    border-color: #34e30c;
    color: white;
}

#btn-allys:hover {
    background-color: #29cd04;
    color: white;
}

.divBoxInv {

    border-radius: 0 15px 15px 0;
    margin: 0px auto;
    border: 1px black;
    border-style: solid;
    font-weight: lighter;
    margin-bottom: 20px;
    text-align: center;
    width: 30%;
}

.btn-salir {
    background-color: #f50b0b;
    border-color: #f50b0b;
    color: white;
}

.div-skeleton {
    width: 20%;
    margin: 0 auto;
    text-align: center;

}

@media only screen and (max-width: 1225px) {



    #dashboard-table {
        width: 90%;
        margin: 0 auto;
        text-align: center;

    }

    .btn-dashboard {
        width: 90%;
    }

    .divBoxInv {
        width: 90%;
    }

    .div-skeleton {
        width: 80%;
        margin: 0 auto;
        text-align: center;

    }
}



.accordion-body {
    padding: 20px 0px;
}

.invitation-title-div {
    display: unset;
    width: 100%;
}

.modal-details {
    max-width: 60% !important;
}

.modal-details .row>* {
    margin-bottom: 10px;
}

.modal-details .accordion-button {
    border: solid;
    border-radius: 10px !important;
    border-color: #03A9F4;
    color: #03A9F4;
    font-weight: bold;
    text-align: center;
}

.modal-details .accordion-button:not(.collapsed) {
    color: white !important;
    background-color: #03A9F4 !important;
    border: solid;

}

.modal-details .accordion-button::after {
    display: none;
}

.btn-custom {
    color: white !important;
    background-color: #03A9F4 !important;
}

.modal-details .card {
    box-shadow: none !important;
}

.header-instalment .accordion-button {
    border-color: black;
    color: black;
    font-weight: unset;
    font-size: 13px;
}

.header-instalment .accordion-button:not(.collapsed) {
    color: black !important;
    background-color: white !important;
    border: solid;

}

.accordion-movements {
    width: 95%;
}

.transfer-button {
    display: inline-block;
    height: 50px;
    line-height: 40px;
    background-color: white;
    border: 0px;
    border-radius: 4px;
    padding: 0px;
    color: white;
    cursor: pointer;

    width: 50px;
}

.transfer-button::before {
    content: "";
    display: inline-block;
    width: 50px;
    height: 50px;
    margin-right: 8px;
    background-image: url('../Images/bancolombia-seeklogo.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    vertical-align: middle;
}

.StoreGPS-button {
    display: inline-block;
    height: 50px;
    line-height: 40px;
    background-color: white;
    border: 0px;
    border-radius: 4px;
    padding: 0px;
    color: white;
    cursor: pointer;

    width: 50px;
}

.StoreGPS-button::before {
    content: "";
    display: inline-block;
    width: 50px;
    height: 50px;
    margin-right: 8px;
    background-image: url('../Images/store_gps.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    vertical-align: middle;
}

.ATM-button {
    display: inline-block;
    height: 50px;
    line-height: 40px;
    background-color: white;
    border: 0px;
    border-radius: 4px;
    padding: 0px;
    color: white;
    cursor: pointer;

    width: 50px;
}

.ATM-button::before {
    content: "";
    display: inline-block;
    width: 50px;
    height: 50px;
    margin-right: 8px;
    background-image: url('../Images/ATM.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    vertical-align: middle;
}

.APP-button {
    display: inline-block;
    height: 50px;
    line-height: 40px;
    background-color: white;
    border: 0px;
    border-radius: 4px;
    padding: 0px;
    color: white;
    cursor: pointer;

    width: 50px;
}

.APP-button::before {
    content: "";
    display: inline-block;
    width: 50px;
    height: 50px;
    margin-right: 8px;
    background-image: url('../Images/APP.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    vertical-align: middle;
}

.modal-payment .modal-content {
    min-height: 100% !important;
}

.modal-payment option {
    font-weight: bold;
}

.modal-payment option:disabled {
    font-weight: unset;
}

.modal-payment {
    height: 80%;
    max-width: 40% !important;
    min-height: 90%;
}

.waybox-modal {
    top: 20px !important;
    height: 90% !important;
}

.modal-transfer .modal-content {
    min-height: 100% !important;
}



.modal-transfer {
    height: 95%;
    max-width: 45% !important;

}

@media only screen and (max-width: 770px) {

    .modal-payment {
        min-height: 95%;
        max-width: 100% !important;

    }


    .modal-transfer {
        min-height: 98%;
        max-width: 100% !important;
    }

    .invitation-title-div {
        display: unset;
    }

    .modal-details {
        max-width: 100% !important;
    }

    .dashboard-accordion {
        width: 100%;
        margin: 0 auto;
    }

    .barra-aliado {
        border-radius: 0 15px 0 0;
    }

    .titulos-mis-creditos {
        font-size: 12px;

    }

    .textos-mis-creditos {
        font-size: 14px;


    }

    .waybox-button::before {
        display: none !important;
    }
}

.modal-transfer .card {
    box-shadow: none !important;
}

.waybox-button {
    float: right;
    background-color: unset !important;
    width: 200px;
    font-size: 0px !important;
    line-height: 0px !important;
    height: 50px !important;
    width: 50px !important;
    background-image: url("../Images/WompiImg.png") !important;
    background-size: 50px 50px !important;
    padding: 0px !important;
}

.waybox-button strong {
    font-size: 0px !important;
}

.waybox-button::before {
    display: none !important;
}