
#vi-table {
    width: 60%;
    margin: 0 auto;
}

#vi-table td {
    padding: 10px;
}

@media only screen and (max-width: 1100px) {

    #vi-table{
        width: 100%;
        margin: 0 auto;
    }

    .divBox {
        width: 90%;

    }

}