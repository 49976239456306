
#svl-table {
    width: 60%;
    margin: 0 auto;
}

#svl-table td {
    padding: 10px;
}

.divText{
    text-align: center;
    width: 50%;
    margin: 0 auto;
}
@media only screen and (max-width: 1100px) {

    #svl-table{
        width: 100%;
        margin: 0 auto;
    }

    .divBox {
        width: 90%;

    }

    .divText{
        text-align: center;
        width: 90%;
    }

}