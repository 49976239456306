
.my-modal {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100vh;
    background-color: rgb(0, 0, 0, 0.75);
    display: none;
    justify-content: center;
    align-items: center;
}

@media (max-width: 600px) {
    .my-modal {
        align-items: unset;
     
    }
    
}

.my-modal.is-open{
    display: flex;
}

.my-modal-container{
    position: absolute;
    min-width: 100%;
    min-height: 200px;
    background-color: #fff;
    padding: 1rem;
    text-align: center;
}

.my-modal-close{
    margin-top: 20px;
    margin-bottom: 20px;
}