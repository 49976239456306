#step-one-table {
    width: 50%;
    margin: 0 auto;
}

.imgAddress {
    max-width: 20px;
}

.floating-label-ro1 {
    transform: none !important;
    opacity: 1 !important;
}

.floating-label-ro2 {
    transform: scale(.85) translateY(-.5rem) translateX(.15rem) !important;
}


#step-one-table td {
    padding: 10px;
}

.btn-mp {
    background-color: #00A9EB;
    border-color: #00A9EB;
    color: white;
}

.btn-mp:hover {
    background-color: #00a0df;
    color: white;
}

@media only screen and (max-width: 700px) {

    #step-one-table {
        width: 100%;
        margin: 0 auto;
    }

    #step-one-table td {
        display: block;

    }
}