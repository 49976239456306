#table-sign {
    width: 40%;
    margin: 0 auto;
    text-align: center;
    color: black;
}


.divBox-esign {
    font-size: 15px;
    font-weight: 500;
    margin: 0px auto;
    border: 1px rgb(211, 211, 211);
    border-radius: 5px;
    border-style: solid;
    padding: 10px;
    cursor: pointer;
    text-align: left;
    width: 50%;
    background-color: #f1f1f1;
}

.modal {
    padding-left: 0 !important;
}

#table-sign .form-control {
    display: initial;
}

#table-sign .input-otp {
    width: 80%;
    text-align: center;
    font-weight: bold;
}

.blank-td {
    width: 100px;
}

@media only screen and (max-width: 1100px) {

    #table-sign {
        width: 95%;
        margin: 0 auto;
    }

    .divBox-esign {
        width: 90%;

    }

    #table-sign .input-otp {
        width: 90%;
        text-align: center;
    }

    .blank-td {
        width: 10px;
    }
}

#table-sign input::-webkit-outer-spin-button,
#table-sign input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

#table-sign input[type=number] {
    -moz-appearance: textfield;
}