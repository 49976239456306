@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.containerPrincipalChangePwReq {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    background-color: #00A9EB;
    min-height: 100vh;
    align-items: center;
    overflow: hidden;
    max-width: 100%;
}

.containerImagenChangePwReq {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 3vh 3vh 10vh 3vh;
}

.imagenMeddiPayChangePwReq {
    width: 50%;
}

.containerOtrosChangePwReq {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 0 10vh 0;
    justify-content: center;
    width: 100%;
}

.textEmailChangePwReq {
    width: 50%;
    font-size: 16px;
    background-color: white;
    padding: 10px 32px 10px 16px;
    border-radius: 6px 6px 6px 6px;
    border: 2px solid white;
    outline: none;
    text-align: center;
    margin: 10px 10px 10px 10px;
}

.textDangerChangePwReq {
    color: red;
    font-size: medium;
}

.buttonEnviarChangePwReq {
    width: 45%;
    padding: 16px;
    font-size: 18px;
    border: none;
    border-radius: 6px 6px 6px 6px;
    cursor: pointer;
    outline: none;
    background: #79D853;
    color: white;
    margin: 10px 50px 10px 50px;
}

.containerResposeOkChangePwReq {
    align-items: center;
    text-align: center;
    color: white;
    font-family: Montserrat, Verdana, Geneva, Tahoma, sans-serif;
    font-size: 3vh;
}

.olvidoRefChangePwReq {
    color: black;
    margin: 10px 50px 10px 50px;
    font-size: medium;
}

@media (max-width: 600px) {
    .containerImagenChangePwReq {
        padding: 0 0 3vh 0;
    }

    .textEmailChangePwReq {
        width: 90%;
    }

    .buttonEnviarChangePwReq {
        width: 80%;
    }

    .imagenMeddiPayChangePwReq {
        width: 80%;
    }
    
}