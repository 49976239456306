#v-table {
  width: 60%;
  margin: 0 auto;
}

#v-table td {
  padding: 10px;
}

#problems-table {
  width: 60%;
  margin: 0 auto;
  text-align: center;
}

#problems-table td {
  padding: 10px;
}

.divText {
  text-align: center;
  width: 50%;
  margin: 0 auto;
}

.lds-dual-ring {
  display: inline-block;
  width: 130px;
  height: 140px;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 114px;
  height: 114px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #000000;
  border-color: #000000 transparent #000000 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


.modal-problems {
  max-width: 70% !important;
  top: 100px;
}

@media only screen and (max-width: 1100px) {

  #v-table {
    width: 100%;
    margin: 0 auto;
  }

  .divBox {
    width: 90%;

  }

  .divText {
    text-align: center;
    width: 90%;
  }

  .modal-problems {
    max-width: 100% !important;
    top: 50px;
  }

  #problems-table {
    width: 100%;
    margin: 0 auto;
  }


}