@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.containerPrincipalLogin {
    background-color: #00A9EB;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    display:flex;
    overflow: hidden;
}

.divisorLogin {
    padding: 10px 10px 10px 10px;
}

.containerImagenLogin {
    padding: 3vh 3vh 3vh 3vh;
    text-align: center;
}

.imagenMeddiPayLogin {
    width: 100%;
}

.containerLoginLogin {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 10% 0 10% 0;
    border-left: 1px solid black;
}

.textUsuarioLogin {
    width: 45vh;
    font-size: 16px;
    background-color: white;
    padding: 10px 32px 10px 16px;
    border-radius: 6px 6px 6px 6px;
    border: 2px solid white;
    outline: none;
    text-align: center;
    margin: 10px 10px 10px 10px;
}

.textDangerLogin {
    color: red;
    font-size: medium;
}

.olvidoRefLogin {
    color: black;
    margin: 10px 50px 10px 50px;
    font-size: medium;
}

.capchaLogin {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00A9EB;
}

.butonAccederLogin {
    width: 40vh;
    padding: 16px;
    font-size: 18px;
    border: none;
    border-radius: 6px 6px 6px 6px;
    cursor: pointer;
    outline: none;
    background: #79D853;
    color: white;
    margin: 10px 50px 10px 50px;
}

@media (max-width: 600px) {
    .containerLoginLogin {
        flex-direction: column;
        padding: 0 0 30vh 0;
    }

    .imagenMeddiPayLogin {
        width: 80%;
        height: auto;
    }

    .containerLoginLogin {
        border-left: none;
    }

    .divisorLogin {
        padding: 0;
    }
    
}